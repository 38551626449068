.timeline {
	flex: 0 100%;
	margin: 15px 0;

	.scroll {
		display: flex;
		overflow-y: hidden;
		overflow-x: auto;
		white-space: nowrap;
		scrollbar-color: #a3a3a3 #d7d7d7;
		scrollbar-width: thin;
	}

	.timeline-action-container {
		flex: 0 0 30px;
		height: 100px;
		padding-top: 25px;
		position: relative;

		.timeline-action {
			float: left;
			height: 75px;
			position: relative;
			width: 100%;

			.timeline-action-stop {
				background-color: #469DE1;
				border: 5px solid #fff;
				border-radius: 30px;
				float: left;
				height: 36px;
				left: -3px;
				line-height: 36px;
				position: absolute;
				top: -3px;
				text-align: center;
				width: 36px;
				z-index: 1;

				svg {
					color: #fff;
					font-size: 16px;
				}
			}

			.timeline-action-foot {
				position: absolute;
				top: 40px;
				width: 130px;

				span {
					font-size: 12px;
					float: left;
					font-weight: 600;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 100%;
				}
			}
		}
	}

	.timeline-modality-container {
		flex: 0 0 250px;
		height: 100px;
		padding-top: 25px;
		position: relative;

		p {
			padding: 0 10px;
			font-size: 11px;
			font-style: italic;
			position: absolute;
			text-align: center;
			top: 5px;
			width: 100%;

			> span {
				border-right :1px solid #333;
				padding: 0 5px;

				&:last-child {
					border: none;
				}
			}
		}

		.timeline-modality {
			background-color: #469DE1;
			float: left;
			height: 16px;
			line-height: 16px;
			position: relative;
			text-align: center;
			margin-top: 10px;
			width: 250px;	

			svg {
				color: #fff;
				font-size: 12px;
			}
		}
	}
}
